.vertical-line {
    width: 100%;
    height: 3px;
    background-color: #ea7f00;
}
.p-heading {
    font-size: 30px;
    font-weight: bold;
    font-family: Lora;
}

/* costomized multiple carousel arrow */
.react-multiple-carousel__arrow {
    background: gray !important;
    visibility: hidden;
    margin-top: -70px;
    /* outline: none !important; */
}
.react-multiple-carousel__arrow ::before {
    font-size: 32px;
}
.react-multi-carousel-list:hover .react-multiple-carousel__arrow { 
    visibility: visible; 
    /* outline: none !important; */
}
.react-multiple-carousel__arrow--left {
    left: 0;
}
.react-multiple-carousel__arrow--right {
    right: 0;
}
/* end carousel */


@media only screen and (min-width: 768px) {    
    .story-content span {
        font-family: Lora;
        font-size: 36px;
        font-weight: bold;
    }
    .story-content p {
        font-weight: normal;
        font-size: 18px;
    }
    .story-content button {
        background: #2baadf;
        color: #fefcff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 5px;
    }
    
    .t-in-p {
        width: 100%;
        border: none;
        box-shadow: none !important;
        background: transparent !important;
        padding: 10px;
    }
    .publilcation-image {
        width: 65px;
        height: 65px;
        border-radius: 4px;
    } 
    .ma-profile {
        width: 120px !important;
        height: 120px !important;
        object-fit: cover;
        object-position: center;
    }

    .morePub-card {
        width: 220px !important;
        box-shadow: none !important;
        background: rgba(247, 247, 247, 0.49) !important;
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
    }
    .morePub-cardmedia {
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
        object-fit: cover;
        object-position: center;
        height: 250;
    }

    .pay-pub-profile {
        width: 150px !important;
        height: 150px !important;
        object-fit: cover;
        object-position: center;
    }
}

@media only screen and (max-width: 766px) {    
    .story-content span {
        font-family: Lora;
        font-size: 32px;
        font-weight: bold;
    }
    .story-content p {
        font-weight: normal;
        font-size: 18px;
    }
    .story-content button {
        background: #2baadf;
        color: #fefcff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 5px;
    }
    
    .t-in-p {
        width: 100%;
        border: none;
        box-shadow: none !important;
        background: transparent !important;
        padding: 10px;
    }
    .publilcation-image {
        width: 65px;
        height: 65px;
        border-radius: 4px;
    } 
    .ma-profile {
        width: 90px !important;
        height: 90px !important;
        object-fit: cover;
        object-position: center;
    }

    .morePub-card {
        width: 90% !important;
        box-shadow: none !important;
        background: rgba(247, 247, 247, 0.49) !important;
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
    }
    .morePub-cardmedia {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        object-fit: cover;
        object-position: center;
    }

    .pay-pub-profile {
        width: 150px !important;
        height: 150px !important;
        object-fit: cover;
        object-position: center;
    }
}