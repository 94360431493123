/* sign in button */
.button1 {
    font-weight: bold;
    border-radius: 4px;
    color: #ffff;
    background-color: #e98c37;
    width: 117px;
    height: 35px;
    border: none;
}

/* .button1:hover {
    background-color: #ff7c02;
} */

/* header bar position */
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: white;
}

/* header logo along with |"text" part */
.header-logo {
    /* width: 500px; */
    height: 50px;
    object-fit: contain;
}

@media only screen and (max-width: 600px) {
    .navbrpadding {
        padding-top: 1rem;
    }
}

@media only screen and (min-width: 768px) {
    .navbrpadding {
        padding: 0.5rem;
    }
}

.navbrpadding h6 {
    text-align: left;
    font: normal;
    letter-spacing: 3px;
    color: #797979;
}

.topsection h6::before {
    content: "";
    width: 3px;
    height: 22px;
    border-radius: 1.5px;
    background-color: #FAA422;
    position: absolute;
}

.sideline {
    padding-left: 15px;
    max-width: 400px;
    display: block;
    font-weight: 600px;
}

/* modal popup */
.email-link1 {
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: lightgray;
    border-width: 1px;
    width: 60%;
}

input.email-link1:focus {
    outline-width: 0;
}

input.email-link1::placeholder {
    color: lightgray;
}

button.close:focus {
    outline-width: 0;
}

/* search bar */
.search {
    width: auto;
    border-top: thin solid #e5e5e5;
    border-right: thin solid #e5e5e5;
    border-bottom: 0;
    border-left: thin solid #e5e5e5;
    box-shadow: 0px 1px 1px 1px #e5e5e5;
    outline: none;
    border-radius: 5px;
    background: #fffcef;
}

#button-holder {
    background-color: #f1f1f1;
    border-top: thin solid #e5e5e5;
    box-shadow: 1px 1px 1px 1px #e5e5e5;
    cursor: pointer;
    float: left;
    height: 27px;
    margin: 11px 0 0 0;
    text-align: center;
    width: 50px;
}

#button-holder img {
    margin: 4px;
    width: 20px;
}


/* dropdown on button hover */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #ffe7cc;
}

/* logout user dropdown */
.dropdown-user {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -70px;
    margin-top: 15px;

}

.dropdown-user a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-user a:hover {
    background-color: #ffe7cc;
}

/* .dropdown:hover .dropdown-content {display: block;} */

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.dropdown-menu.show {
    top: 6px !important;
    padding-left: 12px !important;
}